/**
 * PL - .world
 * DE - .space
 * IT - .life
 * BE - .casa
 * AT - .art
 * NL - .work
 * USA - .one
 * UK -.in
 * (https://batmaid.myjetbrains.com/youtrack/issue/BD-634)
 */

import { IS_BROWSER } from "@config/consts";

import { AVAILABLE_COUNTRIES } from "@typings/globals";

const isHost = (prodDomain: string, stagingDomain: string): boolean => {
  const { host } = window.location;
  return host.indexOf(prodDomain) > 0 || host.indexOf(stagingDomain) > 0;
};

export const resolveCountryFromUrl = (): AVAILABLE_COUNTRIES => {
  let country = AVAILABLE_COUNTRIES.CH;

  if (!IS_BROWSER) {
    return country;
  }

  if (isHost(".ch", ".club")) {
    country = AVAILABLE_COUNTRIES.CH;
  }

  if (isHost(".lu", ".xyz")) {
    country = AVAILABLE_COUNTRIES.LU;
  }

  if (isHost(".fr", ".pro")) {
    country = AVAILABLE_COUNTRIES.FR;
  }

  if (isHost(".pl", ".world")) {
    country = AVAILABLE_COUNTRIES.PL;
  }

  if (isHost(".de", ".space")) {
    country = AVAILABLE_COUNTRIES.DE;
  }

  if (isHost(".it", ".life")) {
    country = AVAILABLE_COUNTRIES.IT;
  }

  if (isHost(".at", ".art")) {
    country = AVAILABLE_COUNTRIES.AT;
  }

  if (isHost(".be", ".casa")) {
    country = AVAILABLE_COUNTRIES.BE;
  }

  if (isHost(".nl", ".work")) {
    country = AVAILABLE_COUNTRIES.NL;
  }

  return country;
};
